<template>
  <div>
    <div class="mb">
      Lector Entradas QR
    </div>
    <div class="center stream">
      
      
      <qr-stream v-if="!success && !loading && !err" @init="onInit" @decode="onDecode" class="mb">
      </qr-stream>

      <spring-spinner
                v-if="loading"
                :size="60"
                color="blue"
      />

        
        <va-card v-if="success && !loading">
        <va-card-title>Validador de Entradas</va-card-title>
        <va-card-content>
          <h1 class="va-h4">Acceso Confirmado</h1>

          <p>Nombre: {{ticket.invoice_items[this.index].first_name}} {{ticket.invoice_items[this.index].last_name}}</p>

          <p>Asiento: {{ticket.invoice_items[this.index].seat_name}}</p>


           <h3 @click="restartSearch()">Reiniciar Busqueda</h3>
        </va-card-content>
      </va-card>



       <va-card v-if="err && !loading">
        <va-card-title>Validador de Entradas</va-card-title>
        <va-card-content>

          <h1  style="color: red;" class="va-h4">ERROR: Acceso Negado</h1>
          <p>{{error_d}}</p>
          
          


           <h3 @click="restartSearch()">Reiniciar Busqueda</h3>
        </va-card-content>
      </va-card>

    
    </div>
    <div class="result">
      Resultado: {{result}}
    </div>
  </div>
</template>

<script>
import { QrStream } from 'vue3-qr-reader'
import { SpringSpinner } from 'epic-spinners';
  import { apiService } from '../../common/api.services.js'



export default {

  components: { QrStream, SpringSpinner},

  props: {
    event: {
      required: true,
    },
  },

  data () {
    return {
      result: '',
      error: '',
      loading: false,
      success: false,
      err: false,
      ticket: {},
      index: 0,
      error_d: ''
    }
  },

  methods: {

    restartSearch(){
      this.result=""
      this.err=false
      this.loading=false
      this.success=false
      this.index=0


    },


    async onDecode (result) {
      this.loading=true

      var fields = result.split('hash');
      var invoice = fields[0];
      this.index = fields[1];

      
      

      let endpoint = '/api/invoices/' + invoice + "/"

      
      
      apiService(endpoint).then((data) => {
          if (data!=undefined){

          this.ticket = data

          this.ticket.invoice_items

          //Unos IF DE VALIDACIÓN


        if (this.ticket.invoice_items[this.index].status==true){

          this.secondValidation (this.ticket.invoice_items[this.index])
        }else{
          "no llegue"
        }


          }else{
            this.error_d="No se ha encontrado la entrada."

            this.err=true
            this.loading=false
          
          }
         

        })

        

    },



    async secondValidation (invoiceItem) {

      console.log("llego aca")

      let event_id= this.event.id
      let idt=invoiceItem.id

      let endpoint = `/api/update-invoice-item/${event_id}/${idt}/`;

      console.log(endpoint)
      
      apiService(endpoint).then((data) => {
        console.log(data)
          if (data=="Entrada Procesada"){
          this.success=true
          this.loading=false

          }else{
            this.error_d="La entrada ya fue utilizada o no pertenece al evento."

            this.err=true
            this.loading=false
          //ok
          }
         

        })

        

    },



    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    }
  }
}
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}
</style>

<style scoped>
.stream {
  max-height: 500px;
  max-width: 500px;
  margin: auto;
}
.frame {
  border-style: solid;
  border-width: 2px;
  border-color: red;
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
}
</style>