<template>
   <div>

       <button @click="generateReport">ProbarPDF</button>
     <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="FomoTicket"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a6"
        pdf-orientation="landscape"
        pdf-content-width="1100px"
        @beforeDownload="beforeDownload($event)"
        ref="html2Pdf"
    >
          <template  v-slot:pdf-content>
               <div class="table-responsive rounded" style="padding-top: 100px; padding-left: 200px; padding-right: 200px; border-color: red; border-radius: 6px !important; ">
        <table class="table table-bordered" style="border-radius: 30px !important;">
            <thead>
                <tr>
                    <th>
                        <img style="border: none;" src="https://media.discordapp.net/attachments/912784937046925352/1108108551093756005/LOGOS_FOMO_TODAS_LAS_VARIACIONES-01_1.png?width=2880&height=256" width="290" height="25" class="img-fluid img-thumbnail" alt="Sheep">
                    </th>

                    
                </tr>
            </thead>
            <tbody>
               
                <tr>
                    <td>

                        <div class="ticket-info">
			<p style="margin-right: 110px; margin-left: 110px;" class="date">
				<span style="font-weight: 900;">EntradaQR</span>
				<span class="june-29">Pase VIP</span>
				<span style="font-weight: 900;">EntradaQR</span>


			</p>






			<div style="padding: 0px; margin: 0px" class="show-name">

                <p style="padding-top: 10px; margin-right: 110px; margin-left: 110px;" class="date"><qr-code :size="200" :text="invoice+'hash'+index"></qr-code></p>
                


				<h1 style="padding: 0px; margin: 0px">KIA OPEN - PGA TOUR LA 2023</h1>
				<h2 style="padding: 0px; justify-content: center; text-align: center; margin: 0px">JUAN SEBASTIÁN CÁRDENAS</h2>
			</div>
			<div class="time">
				<p style="padding: 0px; margin: 0px">4 al 7 de mayo, 2023</p>
				<p style="padding: 0px; margin: 0px">ENTRÁDA VÁLIDA PARA LOS 4 DÍAS DEL EVENTO</p>
			</div>
			<p style="padding: 0px; margin: 0px" class="location"><span>Quito Tenis y Gold Club</span>
				<span style="padding: 0px; margin: 0px" class="separator"><i class="fas fa-qrcode"></i></span><span>Quito</span>
			</p>
		</div>




                    </td>
                </tr>
            </tbody>
        </table>
            <div class="html2pdf__page-break"/>
    </div>



 
        </template>
    </vue3-html2pdf>

   </div>

</template>

<script>

import Vue3Html2pdf from 'vue3-html2pdf'

 
export default { 
        name: 'PdfGeneration',
        props:{
        ticketsTable: {
          type: Object
       },

       event: {
          type: Object
       },

       invoice: {
          type: Number
       },

        },

        data(){
     return{
       todos: [
      { title: 'Article 3', text:"test1" },
      { title: 'Article 4', text:"test2" },
      { title: 'Article 5', text:"test3" }
    ],
       name: "kkakakak",
      
       
     }
     

   },



    components: {
        Vue3Html2pdf
    },

    mounted(){
        //this.generateReport()


    },
    methods: { 
        generateReport () {
            this.$refs.html2Pdf.generatePdf()
        },

          hasDownloaded($event){

              console.log("mii")
            console.log($event)

            this.final_file = new File([$event], 'ticket.pdf');
           
        },

        async beforeDownload ({ html2pdf, options, pdfContent }) {
           var worker = await html2pdf().set(options).from(pdfContent).toPdf().output('blob').then(function (data) {
                   return data;


        });
         console.log(worker)
        var file=new File([worker], 'ticket.pdf');
        console.log(file)

        this.$emit('messageFromPdfGeneration', file)


        


        },

    },
}
</script>
