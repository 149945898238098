<template>


 <section>

      <div class="container cont">

        <form class="d-inline" method="post" @submit.prevent="continueSale()" style="background-color: transparent;max-width: 400px;"><small class="form-text text-muted" style="font-family: Quicksand, sans-serif;">Paso 2 de 3</small>
                <div>
                 <h2
                    class="text-justify va-h6" style="color: rgb(255,255,255);font-family: Quicksand, sans-serif;"><strong>Datos del Envio</strong></h2>
                    <small class="form-text text-muted" style="font-family: Quicksand, sans-serif;">A este correo enviaremos tus entradas</small>
                    <div @keyup="getCustomer()" class="form-group"><input class="form-control" type="email"  v-model="email" name="email" id="email" placeholder="Email" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required=""></div>

                    <small v-if="require_account" class="form-text text-muted" style="font-family: Quicksand, sans-serif;">A este número nos contactaremos si es necesario</small>
                    <div v-if="require_account" class="form-group"><input class="form-control" type="text" v-model="phone" name="phone" id="phone" placeholder="Teléfono" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required=""></div>
                
                    

                    <small v-if="require_account" class="form-text text-muted" style="font-family: Quicksand, sans-serif;">Dirección de facturación</small>
                    <div v-if="require_account" class="form-group"><input class="form-control" type="text" v-model="address" name="address" id="address" placeholder="Dirección de Domicilio" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required=""></div>
                


                    <small v-if="require_account" class="form-text text-muted" style="font-family: Quicksand, sans-serif;">Ingresa una contraseña para ver más rápido y facíl tus entradas</small>
                    <div v-if="require_account" class="form-group"><input class="form-control" type="password" v-model="password" name="email" id="email" placeholder="Contraseña" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required=""></div>
                    
                    


                    <div v-if="require_account" style="padding-left: 22px;" class="form-check"><input class="form-check-input" type="checkbox" id="check1" required="" name="option1" value="something"><label style="padding-top:5px;" class="form-check-label text-muted">Al continuar aceptas los términos y condiciones.</label></div>
                
                
                </div>


                <div v-if="userSearched">
                <h2
                    class="text-justify" style="color: rgb(255,255,255);font-family: Quicksand, sans-serif;"><strong>Participantes</strong></h2>
                    <small class="form-text text-muted" style="font-family: Quicksand, sans-serif;">Ingresa a continuación los nombres y apellidos de las personas que asistiran al evento.</small>
 
                    
                  <div class="padding-bottom: 20px;" v-for="(product, index)  in ticketsTable" :key="index ">
                    <small class="form-text text-muted" style="font-family: Quicksand, sans-serif;">Entrada: {{product.name}}</small>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group"><input class="form-control" v-model="ticketsTable[index].first_name" type="text" name="cc" id="email" placeholder="Nombres del Participante" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required=""></div>
                    </div>

                    <div class="col-6">
                      <div class="form-group"><input class="form-control" v-model="ticketsTable[index].last_name" type="text" name="cc" id="email" placeholder="Apellidos del Participante" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required=""></div>
                    </div>


                    <div class="col-12" v-if="event.id==13">
                      <div class="form-group"><input class="form-control" v-model="ticketsTable[index].ig" type="text" name="cc" id="email" placeholder="Usuario de Instagram" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required=""></div>
                    </div>

                    <div class="col-12" v-if="event.id==51">
                      <div class="form-group"><input class="form-control" v-model="ticketsTable[index].cc" type="text" name="cc" id="email" placeholder="Nro Cédula/Pasaporte" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required=""></div>
                    </div>

                    <div class="col-12" v-if="event.id==8">
                      <div class="form-group"><input class="form-control" v-model="ticketsTable[index].age" type="text" name="cc" id="email" placeholder="Edad" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required=""></div>
                    </div>


                    <div class="col-12" v-if="event.id==14">
                      <div class="form-group"><input class="form-control" v-model="ticketsTable[index].ig" type="text" name="cc" id="email" placeholder="Usuario de Instagram" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required=""></div>
                    </div>

                    <div class="col-12" v-if="event.id==181 && (product.product=='11481' || product.product=='11482' || product.product=='586' || product.product=='587' || product.product=='588' || product.product=='589' || product.product=='590' || product.product=='591' || product.product=='592' || product.product=='593' || product.product=='594' || product.product=='595' || product.product=='596' || product.product=='597' || product.product=='598' || product.product=='599' || product.product=='600' || product.product=='601' || product.product=='602' || product.product=='603' || product.product=='604' || product.product=='605' || product.product=='606' || product.product=='607' || product.product=='608' || product.product=='609' || product.product=='610' || product.product=='611' || product.product=='612' || product.product=='613' || product.product=='615' || product.product=='614' || product.product=='616' || product.product=='617' || product.product=='618' || product.product=='502' || product.product=='503' || product.product=='789' || product.product=='506' )">
                      <div class="form-group"><input class="form-control" v-model="ticketsTable[index].ig" type="text" name="cc" id="email" placeholder="Número de Banner" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required=""></div>
                  </div>  

                  <div class="col-12" v-if="event.id==181 && (product.product=='12923' || product.product=='586' || product.product=='587' || product.product=='588' || product.product=='589' || product.product=='590' || product.product=='591' || product.product=='592' || product.product=='593' || product.product=='594' || product.product=='595' || product.product=='596' || product.product=='597' || product.product=='598' || product.product=='599' || product.product=='600' || product.product=='601' || product.product=='602' || product.product=='603' || product.product=='604' || product.product=='605' || product.product=='606' || product.product=='607' || product.product=='608' || product.product=='609' || product.product=='610' || product.product=='611' || product.product=='612' || product.product=='613' || product.product=='615' || product.product=='614' || product.product=='616' || product.product=='617' || product.product=='618' || product.product=='502' || product.product=='503' || product.product=='789' || product.product=='506' )">
                      <div class="form-group"><input class="form-control" v-model="ticketsTable[index].ig" type="text" name="cc" id="email" placeholder="Código de Estudiante" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required=""></div>
                  </div>  


                    <div class="col-12" v-if="event.id==8">
                      <div class="form-group">
                        <select class="form-control" v-model="ticketsTable[index].size" aria-label="Default select example" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required="">
                        <option value="" disabled  selected>Talla Camiseta</option>
                        <option value="XS/Extra Pequeño">XS/Extra Pequeño</option>
                        <option value="Small/Pequeño">Small/Pequeño</option>
                        <option value="Medium/Mediano">Medium/Mediano</option>
                        <option value="Large/Largo">Large/Largo</option>
                        <option value="XL/Extra Grande">XL/Extra Grande</option>
                        </select>
                    </div>


                    <div class="form-group">
                        <select class="form-control" v-model="ticketsTable[index].gender" aria-label="Default select example" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required="">
                        <option value=0 disabled  selected>Género</option>
                        <option value=1>Masculino</option>
                        <option value=2>Femenino</option>
                        <option value=3>Otro</option>
                        </select>
                        
                    </div>


                     <div v-for="(extra, index)  in extraData" :key="index"  class="col-12" >
                      <div class="form-group"><input class="form-control" v-model="ticketsTable[index].last_name" type="text"   :placeholder="extra.name" style="color: rgb(255,255,255);background-color: #23232A; border-radius=5px; font-family: Quicksand, sans-serif" required=""></div>
                    </div>
                  </div>
                </div>

                

            
                </div>
                
                
                    <div v-if="!loadingUser" class="form-group"><button class="btn btn-primary btn-block" type="submit" style="font-family: Quicksand, sans-serif;">Continuar</button></div>

              <div v-if="loadingUser" class="d-flex justify-content-center">
              <scaling-squares-spinner
                :size="60"
                color="blue"
              />
                             
                             
                             

                          </div> 

                    </div> 

        </form>

       


      </div>

  </section>

    
</template>


  


<script>
import { apiService } from "../../common/api.services.js";
import { ScalingSquaresSpinner   } from 'epic-spinners';


export default {
  
    name: 'GuestName',
    props:{
        productsProp: {
          type: Object
       },
       order_finalF: {
       },

        event: {
           type: Object
       },

       selectedSeats:{
         type: Array
       },

       seatsProducts:{
         type: Array
       }

       
     },


     components: {
    ScalingSquaresSpinner  ,
  },     

     data(){
     return{
       name: "",
       last_name: "",
       cc:"",
       events:[],
       next:null,
       ticketsTable:[],
       order:[],
       total: 0.00,
       fee: 0.00,
       email: "",
       phone: "",
       userSearched: false,
       password: "",
       loadingUser: false,
       term: "",
       address: "",
       
     }
     

   },

    created () {
      this.createTable()
  },

  
   methods:{


    


   

    createTable() {


///fetch('https://api.ipify.org/?format=json')
///.then(function(response) {
  //response.json().then(jsonData => {
    //console.log("Llega a ip");

    //console.log(jsonData);
  //});
//})
//.catch(function(error) {
  //console.log("Error ip");
  //7console.log(error)
//});




//fetch('https://ipapi.co/' + this.term + '/json/')
//.then(function(response) {
  //response.json().then(jsonData => {

    //console.log("Llega a  geo");

    //console.log(jsonData);

  //this.city=jsonData.city
  //this.region=jsonData.region


  //});
//})
//.catch(function(error) {
  //console.log("Error geo");
  //console.log(error)
  //this.city=""
 //// this.region=""
//});
    for (var i = 0; i < this.productsProp.length; i++){      
      for (let index = 0; index < this.productsProp[i].quantity; index++) {

        for (let j = 0; j < this.productsProp[i].quantity_factor; j++) {
          
        let uProduct =  {quantity:1, amount: this.productsProp[i].amount, product: this.productsProp[i].id , name: this.productsProp[i].name, is_courtesy:  this.productsProp[i].is_courtesy, first_name: "",  last_name: "", payment_term: 1, ig: "", age: "", cc: "", size: "", gender: 0, entry: "", seat: ""};
        this.ticketsTable=this.ticketsTable.concat(uProduct)
       
      }
      }
      } 


      let numberF = 0

      for (i = 0; i < this.seatsProducts.length; i++){  

      for (let index = 0; index < this.seatsProducts[i].quantity; index++) {

        for (let j = 0; j < this.seatsProducts[i].quantity_factor; j++) {
         
            let uProduct =  {quantity:1, amount: this.seatsProducts[i].amount, product: this.seatsProducts[i].id , name: this.seatsProducts[i].name, is_courtesy:  this.seatsProducts[i].is_courtesy, first_name: "",  last_name: "", payment_term: 1, ig: "", age: "", cc: "", size: "", gender: 0, entry: "", seat: this.selectedSeats[numberF].id, seat_name: this.selectedSeats[numberF].name, seat_assigned: true};
             this.ticketsTable=this.ticketsTable.concat(uProduct)
             numberF++
       
      }
      }
      } 



      








      if (this.event.id==16){
        this.customer=1
        this.$emit('messageFromGuestName', this.ticketsTable, this.customer)
      }
    },


      getCustomer(){



        this.userSearched=false
      let endpoint = `/api/contacts/?email=${this.email}`; //open pos


      console.log(endpoint)

        clearTimeout(this.timer)

        this.timer = setTimeout(() => {
                
      apiService(endpoint).then(data => {
        if (data.length==0){
          this.require_account=true
          this.userSearched=true

        
        }else{
          this.customer = data[0];
          this.require_account=false
          this.userSearched=true

        }
        this.looking=false

      });
    
    }, 1250)
  },



  continueSale(){

     if (this.userSearched){
      this.loadingUser=true

      
      if(this.require_account==false){
  
      this.$emit('messageFromGuestName', this.ticketsTable, this.customer)
      }else{
        this.createCustomer()
      }
  }
    },


 createCustomer(){
    let first_name=this.ticketsTable[0].first_name
    let last_name=this.ticketsTable[0].last_name
    this.loadingMain=true
    this.success = false
    var profile = new Object();
    profile.phone =  this.phone
    profile.address = this.address
    profile.city = ""
    if (this.password==""){
      this.password="fomoplankk9876"

    }
    if(this.email!=""){
        let endpoint =  `/api/user/create`
       
    apiService(endpoint, "POST", {
  
    password: this.password,
    contact_profile: profile,
    first_name: first_name,
    last_name: last_name,
    email: this.email,
    username: this.email

  })
              .then(data => {
                  if (data.id){
                    this.customer=data
                    console.log(data)
                    this.require_account=false

                   this.continueSale()
        
                  }else{

                      alert("Error al crear la cuenta. Revisa los datos e inténtalo nuevamente.")
                      this.error="Verifique que los datos sean correctos"
                      this.loadingUser=false

                  }

        
               
              })

              

    

      }else {
        this.error="Llene todos los campos"
      }
    },


    


    openEvent(_id) {
      this.$router.push({ name: "event", params: { id: _id } });
    },


    increment (quantity, i) {
      this.availableTickets[i].quantity++
      this.availableTickets[i].total= (this.availableTickets[i].quantity * this.availableTickets[i].amount).toFixed(2)
       this.availableTickets[i].amount=this.availableTickets[i].amount*1
      this.total=this.total + this.availableTickets[i].amount
      this.total.toFixed(2)
    },
    decrement (quantity, i) {
      if(quantity === 0) {
         this.availableTickets[i].quantity=0
           }else{

     
        this.availableTickets[i].quantity--
      this.availableTickets[i].total= (this.availableTickets[i].quantity * this.availableTickets[i].amount).toFixed(2)
       this.availableTickets[i].amount=this.availableTickets[i].amount*1
      this.total=this.total - this.availableTickets[i].amount
      this.total.toFixed(2)
       }
    
      
    }

    
   }
}
</script>

<style>

#imagemPosterSlide{
  padding: 10px;
  
}

.container{
  justify-content:center
}


  
</style>



<style lang="css">
h1 {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.blog-card-blog {
  margin-top: 30px;
}

.blog-card {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: rgb(52, 58, 64);
  background-color: rgb(52, 58, 64);
}



.blog-card .blog-table {
  padding: 15px 30px;
}

.blog-table {
  margin-bottom: 0px;
}

.blog-category {
  position: relative;
  line-height: 0;
  margin: 15px 0;
}

.blog-text-success {
  color: rgb(198,197,197);
}

.blog-card-blog .blog-card-caption {
  margin-top: 5px;
}

.blog-card-caption {
  font-weight: 700;
  font-family: "Roboto Slab", "Times New Roman", serif;
}

.blog-card-caption, .blog-card-caption a {
  color: #333;
  text-decoration: none;
}

p {
  color: #3C4857;
  margin-top: 0;
  margin-bottom: 1rem;
}

.blog-card .ftr {
  margin-top: 15px;
}

.blog-card .ftr .author {
  color: #888;
}

.blog-card .ftr div {
  display: inline-block;
}

.blog-card .author .avatar {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}

.blog-card .ftr .stats {
  position: relative;
  top: 1px;
  font-size: 14px;
}

.blog-card .ftr .stats {
  float: right;
  line-height: 30px;
}

a {
  text-decoration: none;
}


 .d-inline .input {
    border: 0;
    width: 2.5rem;
    text-align: center;
    padding: 0 .5rem;
  }

.quantity-toggle .button {
    background: rgb(198,197,197);
    font-size: 1rem;
    cursor: pointer;
    color: white;
  }


.cont{
   padding-right: 50px;
   padding-left: 50px;
}
.rw{
   padding-right: 5px;
   padding-left: 5px;
  border-top: 1px solid white;



}

@media (max-width: 768px) {
.cont {
    padding-left: 10px;
    padding-right: 10px;
}

.rw{
   padding-right: 10px;
   padding-left: 10px;


}
}


    
</style>

   