<template>
  <div style="padding-top: 10px" class="row row-equal">



    <div class="flex xs12 md4">
      <va-card class="xs3 md4" style="background-color: green">
        <va-card-content>
          <h2 class="va-h2 ma-0" style="color: white">${{ sales.toFixed(2) }}</h2>
          <p style="color: white">Total Ingresos por Ventas</p>
        </va-card-content>
      </va-card>
    </div>

    <div class="flex xs12 md4">
      <va-card class="xs3 md4" style="background-color: blue">
        <va-card-content>
          <h2 class="va-h2 ma-0" style="color: white">{{ tickets }}</h2>
          <p style="color: white">Total Entradas Vendidas</p>
        </va-card-content>
      </va-card>
    </div>

    <div class="flex xs12 md4">
      <va-card class="xs3 md4" style="background-color: orange">
        <va-card-content>
          <h2 class="va-h2 ma-0" style="color: white">{{ courtesies }}</h2>
          <p style="color: white">Total Cortesías Emitidas</p>
        </va-card-content>
      </va-card>
    </div>
   


   
   <div class="row row-equal" style="padding: 0px; margin: 0px;">
    
    <div class="flex md6 xs12" style="padding-top: 20px; padding-bottom: 20px;">
      <va-card class="chart-widget h-100" >
        <va-card-title>Distribución de Ventas</va-card-title>
        <va-card-content>
          <Doughnut :data="generatedData" />
        </va-card-content>
      </va-card>
    </div>
   
    <div class="flex md6 xs12" style="padding-top: 20px; padding-bottom: 20px;">
      <va-card class="chart-widget h-100">
        <va-card-title>Distribución de Compras</va-card-title>
        <va-card-content>
          <Bar :data="generatedBar"/>
        </va-card-content>
      </va-card>
    </div>

    </div>



    <div class="row row-equal" style="padding: 0px; margin: 0px;">
    
    <div class="flex md6 xs12" style="padding-top: 20px; padding-bottom: 20px;">
      <va-card class="chart-widget h-100" >
        <va-card-title>Distribución de Tráfico</va-card-title>
        <va-card-content>
          <Doughnut :data="generatedData2" />
        </va-card-content>
      </va-card>
    </div>
   
    <div class="flex md6 xs12 markup-tables flex" style="padding-top: 20px; padding-bottom: 20px;">
      <va-card class="chart-widget h-100">
        <va-card-title>Distribución de Compras</va-card-title>
        <va-card-content>
          <div class="table-wrapper">
            <table class="va-table va-table--striped va-table--hoverable">
              <thead>
                <tr>
                  <th>Origen</th>
                  <th>Cantidad de Ventas</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td><i class="fab fa-facebook-f"></i> <i class="fab fa-instagram-f"></i> - IG/FB </td>
                  <td>{{generatedData2.datasets[0].data[0]}}</td>
                </tr>
                <tr>
                  <td><i class="fab fa-tiktok"></i> - TikTok</td>
                  <td>{{generatedData2.datasets[0].data[1]}}</td>
                </tr>
                <tr>
                  <td><i class="fas fa-mail-bulk"></i> - Correo Electrónico</td>
                  <td>{{generatedData2.datasets[0].data[2]}}</td>
                </tr>
                <tr>
                  <td><i class="fas fa-people-arrows"></i> - Promotores</td>
                  <td>{{generatedData2.datasets[0].data[3]}}</td>
                </tr>
                <tr>
                  <td><i class="fas fa-qrcode"></i> - Sitio Web</td>
                  <td>{{generatedData2.datasets[0].data[4]}}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </va-card-content>
      </va-card>
    </div>

    </div>






    

    <div class="markup-tables flex" style="padding-top: 20px; padding-bottom: 20px;">
      <va-card>
        <va-card-title>Reporte de Ventas</va-card-title>
        <va-card-content>
          <div class="table-wrapper">
            <table class="va-table va-table--striped va-table--hoverable">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Entradas Emitidas</th>
                  <th>Entradas Restantes</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="event in total_tickets" :key="event.id">
                  <td>{{ event.sect }}</td>
                  <td>{{ event.tkt }}</td>
                  <td>{{ event.rem }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
  import { apiService } from '../../common/api.services.js'

  import { Doughnut, Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)


  export default {
    name: 'TestView',
    data() {
      return {
        events: [],
        next: null,
        loadingTable: true,
      }
    },

    components: {
      Doughnut,
      Bar
    },

    props: {
      tickets: {
        required: true,
      },
      sales: {
        required: true,
      },
      courtesies: {
        required: true,
      },
      eventTickets: {
        required: true,
      },
      generatedData: {
        required: true,
      },


      generatedData2: {
        required: true,
      },


      total_tickets: {
        required: true,
      },
      card: {
        required: true,
      },

      cardPos: {
        required: true,
      },

      bank: {
        required: true,
      },
      cash: {
        required: true,
      },

      event: {
        required: true,
      }
    },

    computed: {
      heading() {
        return [
          {
            name: 'name',
            title: 'Sección',
          },
          {
            name: 'name',
            title: 'Entradas Emitidas',
          },
          {
            name: 'name',
            title: 'Entradas Restantes',
          },
        ]
      },
    },

    created() {
      this.populateBar()
    },

    methods: {
      populateBar() {
        this.generatedBar = {
          labels: ['Tarjeta', 'Transferencia', 'Efectivo', 'Tarjeta (Pos)'],
          datasets: [
            {
              label: '$',
              backgroundColor: 'blue',
              data: [this.card, this.bank, this.cash, this.cardPos],
            },
          ],
        }
      },

     
      openEvent(_id) {
        this.$router.push({ name: 'EventHostView', params: { slug: _id } })
      },

      getEvents() {
        // make a GET Request to the questions list endpoint and populate the questions array

        //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
        let endpoint = '/api/host/events/'

        if (this.next) {
          endpoint = this.next
        }
        this.loadingQuestions = true
        apiService(endpoint).then((data) => {
          this.events = this.events.concat(data)
          this.showLoading = false
          if (data.next) {
            //si no es null
            this.next = data.next
          } else {
            //Si no es null
            this.next = null
          }
        })
      },
    },
  }
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .md6 .xs12{
    padding-top: 20px;
    padding-bottom: 20px;
    
  }
</style>
