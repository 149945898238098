<template>
  <div style="padding-top: 10px" class="row row-equal">
    <div class="markup-tables flex">
      <va-card>
        <va-card-title>Entradas Promocionales</va-card-title>
        <va-card-content>
          <form @submit.prevent="searchEvents()">
            <div class="row">
              <div class="flex md9">
                <va-input
                  v-model="search"
                  placeholder="Ingrese el Nombre, Apellido o Correo"
                  label="Buscar"
                >
                </va-input>
              </div>
            </div>
          </form>


           <spring-spinner
                v-if="loadingMain"
                :size="60"
                color="blue"
      />



          <div class="table-wrapper" v-if="!loadingMain">
            <table class="va-table va-table--striped va-table--hoverable">
              <thead>
                <tr >
                  <th>id</th>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Localidad</th>
                  <th>Asiento</th>
                  <th>Código</th>
                  <th>Monto</th>
                  <th>Estado</th>
                  <th>Comentario</th>
                  <th v-if="is_staff==true || is_staff=='true'">Teléfono</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(event_t, index) in events" :key="index">
                  <td @click="grantAccess(index)">{{ event_t.payment.id }}</td>
                  <td @click="grantAccess(index)">{{ event_t.first_name }}</td>
                  <td @click="grantAccess(index)">{{ event_t.last_name }}</td>
                  <td @click="grantAccess(index)">{{ event_t.product_name }}</td>
                  <td @click="grantAccess(index)">{{ event_t.seat_name }}</td>
                  <td @click="grantAccess(index)">{{ event_t.product_code }}</td>
                  <td @click="grantAccess(index)">{{ event_t.amount }}</td>
                  <td @click="grantAccess(index)" v-if="event_t.payment.status==0">Pendiente</td>
                  <td @click="grantAccess(index)" v-if="event_t.payment.status==1">Emitido</td>
                  <td @click="grantAccess(index)" v-if="event_t.payment.status==3">En Revisión</td>
                  <td @click="grantAccess(index)" v-if="event_t.payment.status==4">Rechazado</td>
                  <td @click="grantAccess(index)" v-if="event_t.payment.comment=='fomoaut'">n/a</td>
                  <td @click="grantAccess(index)" v-else>{{event_t.payment.comment}}</td>
                  <td @click="grantAccess(index)" v-if="is_staff==true || is_staff=='true'">{{event_t.phone}}</td>
                </tr>
              </tbody>
            </table>
          </div>




          <div class="d-flex justify-content-end">
            <div class="md4">
              <spring-spinner
                v-if="loading"
                v-show="next"
                :size="60"
                color="blue"
                style="padding-top: 10px;"
              />

              <button
                v-show="next"
                v-if="!loading"
                @click="getEvents(true)"
                style="
                  width: 100%;
                  border: transparent;
                  background-color: blue;
                "
                class="btn btn-primary"
              >
                Cargar Más
              </button>
            </div>
          </div>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import { apiService } from "../../common/api.services.js";
import { SpringSpinner } from 'epic-spinners';


export default {
  name: "AssistanceList",
  data() {
    return {
      events: [],
      next: null,
      loadingTable: true,
      loadingMain: false,
      success: false,

      search: "",
      loading: false
    };
  },
  components: {
    SpringSpinner,
  },

  props: {
    event: {
      required: true,
    },
  },

  computed: {
    heading() {
      return [
        {
          name: "name",
          title: "Sección",
        },
        {
          name: "name",
          title: "Entradas Emitidas",
        },
        {
          name: "name",
          title: "Entradas Restantes",
        },
      ];
    },
  },

  created() {
    this.getEvents(false);
  },

  methods: {

    async grantAccess(index){

      if(this.events[index].payment.id!="n/a"){



    

      if(confirm("Desea confirmar el acceso manual de " + this.events[index].first_name + " " + this.events[index].last_name)){

    



      let event_id= this.event.id
      let idt=this.events[index].id
      this.loadingMain=true

      let endpoint = `/api/update-invoice-item/${event_id}/${idt}/`;

      console.log(endpoint)
      
      apiService(endpoint).then((data) => {
        console.log(data)
          if (data=="Entrada Procesada"){
          alert ("ÉXITO: Acceso Concedido")
          
          this.success=true
          this.loadingMain=false
          this.search=""

          }else{
             alert ("ERROR: La entrada ya fue utilizada o no pertenece al evento.")

            this.err=true
            this.loadingMain=false
            this.search=""
          //ok
          }
         

        })

        }
  


    }else{
      alert("No se puede validar el acceso. Solicite un comprobante del pago y consulte con un administrador")
    }

    },

    populateBar() {
      this.generatedBar = {
        labels: ["Tarjeta", "Transferencia", "Efectivo"],
        datasets: [
          {
            label: "$",
            backgroundColor: "blue",
            data: [this.card, this.bank, this.cash],
          },
        ],
      };
    },

    populateData() {
      let labelArr = [];
      let backgroundColorArr = [];
      let dataArr = [];

      for (let index = 0; index < this.eventTickets.length; index++) {
        console.log("paso");

        let lname =
          this.eventTickets[index].name + "-" + this.eventTickets[index].code;
        labelArr.push(lname);

        let maxVal = 0xffffff; // 16777215
        let randomNumber = Math.random() * maxVal;
        randomNumber = Math.floor(randomNumber);
        randomNumber = randomNumber.toString(16);
        let randColor = randomNumber.padStart(6, 0);
        let color = `#${randColor.toUpperCase()}`;
        backgroundColorArr.push(color);

        let value =
          this.eventTickets[index].initial_quantity -
          this.eventTickets[index].remaining_tickets;
        dataArr.push(value);

        let obj = Object();
        obj.section =
          this.eventTickets[index].name + "-" + this.eventTickets[index].code;
        obj.tickets = value;
        obj.remaining = this.eventTickets[index].remaining_tickets;
        this.total_tickets.push(obj);
      }
      this.loadingTable = false;
      console.log(labelArr);
      console.log(dataArr);
      console.log(backgroundColorArr);

      this.generatedData = {
        labels: labelArr,
        datasets: [
          {
            label: "Tickets x Sección",
            backgroundColor: backgroundColorArr,
            data: dataArr,
          },
        ],
      };
    },

    openEvent(_id) {
      this.$router.push({ name: "EventHostView", params: { slug: _id } });
    },

    searchEvents() {
      this.events = [];
      this.getEvents();
      this.next = null;
    },

    getEvents(type) {
      // make a GET Request to the questions list endpoint and populate the questions array

      //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
      let endpoint = "/api/invoice-assistance-items/?event=" + this.event.id;


     

      if (this.next && type) {
        endpoint = this.next;
        this.loading=true
      }

      if (this.search != "" && !type) {
        endpoint =
          "/api/invoice-assistance-items/?search=" +
          this.search +
          "&event=" +
          this.event.id;
          
      }


      this.loadingQuestions = true;
      apiService(endpoint).then((data) => {
        this.events = this.events.concat(data.results);
        this.showLoading = false;
        this.loading=false
        if (data.next) {
          //si no es null
          this.next = data.next;
        } else {
          //Si no es null
          this.next = null;
        }
      });
    },

  },
};
</script>

<style lang="scss">
.markup-tables {
  .table-wrapper {
    overflow: auto;
  }

  .va-table {
    width: 100%;
  }
}
</style>
