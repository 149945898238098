<template>


 <section>

      <div class="container cont">

        <small class="form-text text-muted" style="font-family: Quicksand, sans-serif;">Paso 1 de 3</small>
                <h2  class="text-justify va-h6" style="color: rgb(255,255,255);font-family: Quicksand, sans-serif;"><strong>Selección</strong></h2>

        <div  class="table-responsive" style="text-align: center;font-family: Raleway, sans-serif;">
                                                <table class="table ">
                                                    <thead>
                                                        <tr>
                                                            <th style="color: rgb(255,255,255);">Localidad</th>
                                                            <th style="color: rgb(255,255,255);">Precio</th>
                                                            <th style="color: rgb(255,255,255);">Cantidad</th>
                                                            <th style="color: rgb(255,255,255);">Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody >


                                                        <tr  v-for="(eventTicket, index)  in availableTickets" :key="index" style="color: rgb(198,197,197);" >
                                                            <td class="align-middle">
                                                              <img style="max-width: 100px;"  v-if="eventTicket.generic_image!=null" :src="eventTicket.generic_image" class="img-fluid img-thumbnail"  alt="">
                                                              <div>{{eventTicket.name}}</div>
                                                              <div v-if="eventTicket.description!=''" style="font-size: 14px !important;">{{eventTicket.description}}</div>
                                                            </td>
                                                            <td class="align-middle" v-if="eventTicket.is_courtesy">Cortesía</td>
                                                            <td class="align-middle" v-else>${{eventTicket.amount}}</td>
                                                            <td class="align-middle" v-if="dateValidation(eventTicket.expiration_date, eventTicket.sales_date, eventTicket)==1" style="white-space:nowrap"><div  class="quantity-toggle">
                                                                 <button @click="decrement(eventTicket.quantity, index)">&mdash;</button>
                                                                 <input type="text" style="color: white;" :value="eventTicket.quantity" readonly>
                                                                 <button @click="increment(eventTicket.quantity, index)">&#xff0b;</button>
                                                                 </div>
                                                            </td>


                                                            <td class="align-middle"  v-if="dateValidation(eventTicket.expiration_date, eventTicket.sales_date, eventTicket)==2">Próximo Precio</td>


                                                            <td class="align-middle" style="color: red;"  v-if="dateValidation(eventTicket.expiration_date, eventTicket.sales_date, eventTicket)==0">Agotado</td>

                                                            <td class="align-middle">$ {{eventTicket.amount*eventTicket.quantity}}</td>

                                                        </tr>

                                              


                                                    </tbody>
                                              </table>
          </div>

          <div style="padding-top: 10px;" v-if="total_quantity!=0">
        <p  class="d-flex justify-content-end" style="color: white; padding-top: 10px;">Total: ${{subtotal.toFixed(2)}}</p>
        <div style="padding-top: 10px;" class="d-flex justify-content-end">
        <button @click="continueSale()" class="btn btn-primary" type="button" style="padding-top: 10px;padding-bottom: 14px;font-family: Quicksand, sans-serif;"><i class="icon-basket"></i>Reservar Tickets</button>




      </div>

       
         </div>
      </div> 
        
  </section>

    
</template>


<script>
//import { apiService } from "../../common/api.services.js";

export default {
  
    name: 'SaleQuantity',
    
    props:{
        eventTickets: {
          type: Object
       },
       coordinated_fee:{
         required: true
       },

       fixed_fee:{
         required: true
       },


       coordinated_service:{
         required: true
       }
     },

     

     data(){
     return{
       events:[],
       next:null,
       availableTickets:[],
       order:[],
       total: 0.00,
       subtotal: 0.00,
       fee: 0.00,
       total_quantity: 0,
       today: new Date(),
       hasMap: 0
       
     }
     

   },

    created () {
    this.createTable()
    this.today = new Date();
  },

  
   methods:{
      createTable() {


    for (var i = 0; i < this.eventTickets.length; i++){
      console.log(i)

      if (this.eventTickets[i].status==1){
      let uProduct =  {remaining_tickets: this.eventTickets[i].remaining_tickets, host_remaining_tickets: this.eventTickets[i].host_remaining_tickets,   quantity:0, amount: this.eventTickets[i].price,  name: this.eventTickets[i].name, is_courtesy:  this.eventTickets[i].is_courtesy, total: 0, id: this.eventTickets[i].id, sales_date: this.eventTickets[i].sales_date,  expiration_date: this.eventTickets[i].expiration_date, quantity_factor: this.eventTickets[i].quantity, map: this.eventTickets[i].has_map, max_quantity: this.eventTickets[i].max_quantity, description: this.eventTickets[i].description, generic_image: this.eventTickets[i].generic_image};
      this.availableTickets=this.availableTickets.concat(uProduct)
      

    }
        }

      
          
      },

  continueSale(){
    if(this.subtotal!=0){
        //this.fee=(this.subtotal * 0.083333) + 0.5
        let coordinated_fee=parseFloat(this.coordinated_fee)
        let coordinated_service=parseFloat(this.coordinated_service)
        let fixed_fee=parseFloat(this.fixed_fee)

        this.fee=(this.subtotal *coordinated_fee) + (coordinated_service * this.total_quantity) + fixed_fee
      
    }else{
      this.fee=0
    }
      this.total=this.subtotal+this.fee
      
      this.subtotal=this.subtotal.toFixed(2)
      this.fee=this.fee.toFixed(2)
      this.total=this.total.toFixed(2)


      this.$emit('messageFromCart', this.availableTickets, this.subtotal, this.fee, this.total, this.hasMap)
    },


    dateValidation(str, sales,  obj){

      let var1=1

      console.log(sales)

      //0 Agotado
      //1 disponible
      //2 Proximamente

      if (str==undefined || str == null){
        console.log("val1")

       var1=1

      }

   if (str!=undefined || str != null){

    const date = new Date(str);

      
      if (this.today>=date){
               var1=0
      }


    }


      

        if (obj.remaining_tickets<=0 || obj.host_remaining_tickets<=0){
               var1=0
               console.log(var1)


      }

     



      if (sales!=undefined || sales != null){
        console.log("llegue a prox fecha")

        const date2 = new Date(sales);

        if (date2>=this.today){
               var1=2

      }




      }



      
      




   
        return var1


      

    },



    


    openEvent(_id) {
      this.$router.push({ name: "event", params: { id: _id } });
    },


    increment (quantity, i) {

      let max=this.availableTickets[i].max_quantity

      if (this.availableTickets[i].remaining_tickets<max){
        max=this.availableTickets[i].remaining_tickets
      }



      if(quantity === max ) {

        this.availableTickets[i].quantity==this.availableTickets[i].max_quantity
      
      
      }else{

      if (this.availableTickets[i].quantity!=this.availableTickets[i].max_quantity){
      this.availableTickets[i].quantity++
      this.availableTickets[i].total= (this.availableTickets[i].quantity * this.availableTickets[i].amount).toFixed(2)
       this.availableTickets[i].amount=this.availableTickets[i].amount*1
      this.subtotal=this.subtotal + this.availableTickets[i].amount
      this.subtotal.toFixed(2)
      this.total_quantity=this.total_quantity+(this.availableTickets[i].quantity_factor)
      
      if (this.availableTickets[i].map==true){
        this.hasMap++
      }

    }



           }
    },
    decrement (quantity, i) {
      if(quantity === 0) {
         this.availableTickets[i].quantity=0
           }else{

     
        this.availableTickets[i].quantity--
      this.availableTickets[i].total= (this.availableTickets[i].quantity * this.availableTickets[i].amount).toFixed(2)
       this.availableTickets[i].amount=this.availableTickets[i].amount*1
      this.subtotal=this.subtotal - this.availableTickets[i].amount
      this.subtotal.toFixed(2)
      this.total_quantity=this.total_quantity-(this.availableTickets[i].quantity_factor)

      if (this.availableTickets[i].map==true){
        this.hasMap--
      }

       }
    
      
    }

    
   }
}
</script>

<style>

#imagemPosterSlide{
  padding: 10px;
  
}

.container{
  justify-content:center
}


  
</style>



<style lang="css">
h1 {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.blog-card-blog {
  margin-top: 30px;
}

.blog-card {
  display: inline-block;
  position: relative;
  width: 100%;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: rgb(52, 58, 64);
  background-color: rgb(52, 58, 64);
}



.blog-card .blog-table {
  padding: 15px 30px;
}

.blog-table {
  margin-bottom: 0px;
}

.blog-category {
  position: relative;
  line-height: 0;
  margin: 15px 0;
}

.blog-text-success {
  color: rgb(198,197,197);
}

.blog-card-blog .blog-card-caption {
  margin-top: 5px;
}

.blog-card-caption {
  font-weight: 700;
  font-family: "Roboto Slab", "Times New Roman", serif;
}

.blog-card-caption, .blog-card-caption a {
  color: #333;
  text-decoration: none;
}

p {
  color: #3C4857;
  margin-top: 0;
  margin-bottom: 1rem;
}

.blog-card .ftr {
  margin-top: 15px;
}

.blog-card .ftr .author {
  color: #888;
}

.blog-card .ftr div {
  display: inline-block;
}

.blog-card .author .avatar {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}

.blog-card .ftr .stats {
  position: relative;
  top: 1px;
  font-size: 14px;
}

.blog-card .ftr .stats {
  float: right;
  line-height: 30px;
}

a {
  text-decoration: none;
}




  .quantity-toggle input {
    width: 2.5rem;
    text-align: center;
    padding: 0 .5rem;
    background: transparent;
  }
  
  .quantity-toggle button {
    background: transparent;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    
  }


.cont{
   padding-right: 5px;
   padding-left: 5px;
}


@media (max-width: 768px) {
.cont {
    padding-left: 1px;
    padding-right: 1px;
}

.rw{
   padding-right: 10px;
   padding-left: 10px;


}
}

    
</style>

   